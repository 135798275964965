<template>
  <DefaultTemplate page="reset">
    <Layout>
      <div class="main-slot padding-grid text-white">
        <h1 class="text-center font-weight-bold">ตั้งรหัสผ่านใหม่</h1>
        <el-form
          :label-position="'top'"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="login-form"
        >
          <el-form-item label="รหัสผ่านใหม่" prop="password">
            <el-input
              type="password"
              show-password
              @input="validPass"
              v-model="ruleForm.password"
              maxlength="30"
              placeholder="กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวอักษร"
            ></el-input>
          </el-form-item>
          <el-form-item label="ยืนยันรหัสผ่านใหม่" prop="confirmPassword">
            <el-input
              type="password"
              show-password
              v-model="ruleForm.confirmPassword"
              maxlength="30"
              placeholder="กรุณากรอกรหัสผ่านอย่างน้อย 8 ตัวอักษร"
            ></el-input>
          </el-form-item>
          <div class="w-100 mg-t-2">
            <el-row :gutter="20" type="flex" justify="center">
              <el-col :sm="8" :md="6" :lg="5" :xl="6" :offset="0">
                <div class="signin-button">
                  <el-button
                    type="danger"
                    class="w-100"
                    @click="send()"
                    :loading="loading"
                    >ตั้งรหัสผ่านใหม่</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </Layout>
  </DefaultTemplate>
</template>
<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
import Layout from "@/template/LayoutDefaultTemplate";
export default {
  components: {
    DefaultTemplate,
    Layout
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else {
        if (this.ruleForm.confirmPassword !== "") {
          this.$refs.ruleForm.validateField("confirmPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่านอีกครั้ง"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("รหัสผ่านไม่ตรงกัน"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      sPhrase: '',
      ruleForm: {
        password: "",
        confirmPassword: ""
      },
      rules: {
        password: [
          { required: true, message: "กรุณากรอกรหัสผ่าน", trigger: "blur" },
          { validator: validatePass, trigger: "blur" }
        ],
        confirmPassword: [
          { required: true, message: "กรุณากรอกรหัสผ่าน", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" }
        ]
      }
    };
  },
  mounted(){
    this.getSphase()
  },
  methods: {
    async getSphase(){
      try {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.$store.state.sphrase}`;
        let res = await HTTP.get(`key/sign`)
        if(res.data.success){
          this.sPhrase = res.data.data
        }
      } catch (error) {
        
      }
    },
    validPass() {
      let arr = ["..%u2216", "..%c0%af", "%2e%2e%2f", "..%255c", "../", "..\\"];
      let reg = new RegExp(
        /..%u2216|..%c0%af|..%c1%9c|%2e%2e%2f|%2e%2e\/|..%2f|%2e%2e\\|..%5c|%252e%252e%255c|..%255c|\.\.\/|\.\/|\.\.\\|\.\\|\.\.|%00|%0|%2|%5|%%|%c|%d|%e|%f|%p|%u|%x|%s|%n|%\d{1,2}!s|%\d{1,2}!n\w+/g,
        "gi"
      );
      let index = reg.test(this.ruleForm.password);
      let matches = this.ruleForm.password.match(reg);
      if (index) {
        if (matches.length != null) {
          matches.map(data => {
            this.ruleForm.password = this.ruleForm.password.replace(data, "");
          });
        }
        this.$message.error("ห้ามใช้อักขระพิเศษ");
      }
    },
    send() {
      this.loading = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let tempPassword = this.CryptoJS.AES.encrypt(
            this.ruleForm.password,
            this.sPhrase
          ).toString();

          let obj = {
            password: tempPassword,
            token: this.$route.params.token
          };
          // console.log(obj);

          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.post("reset/password", obj)
            .then(res => {
              // console.log(res);
              if (res.data.success) {
                this.$message({
                  message: "แก้ไขรหัสผ่านสำเร็จ กรุณาเข้าสู่ระบบ",
                  type: "success"
                });
                this.loading = false;
                this.$router.push("/").catch(err => {});
              } else {
                this.$message({
                  message:
                    "ขออภัย กรุณาตรวจสอบความถูกต้องอีกครั้ง (" +
                    res.data.data +
                    ")",
                  type: "error"
                });
                this.loading = false;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style scoped>
.main-slot {
  min-height: calc(100vh - 402px);
}

@media (min-width: 1281px) {
  .main-slot {
    min-height: calc(100vh - 343px) !important;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .main-slot {
    min-height: calc(100vh - 343px) !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .main-slot {
    min-height: calc(100vh - 344px) !important;
  }
}

@media (max-width: 767px) {
  .main-slot {
    min-height: calc(100vh - 345px) !important;
  }
}

@media (max-width: 414px) {
  .main-slot {
    min-height: calc(100vh - 352px) !important;
  }
}

@media (max-width: 375px) {
  .main-slot {
    min-height: calc(100vh - 346px) !important;
  }
}
</style>
